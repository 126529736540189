import React from 'react'
import CreateHideDeliveryMethods from './createHideDeliveryMethods'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function HideDeliveryMethodsItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateHideDeliveryMethods {...props} id={id} />
}

export default HideDeliveryMethodsItem;